import * as types from './actionTypes';
import * as tenantsApi from '../../api/tenantsApi';
import { beginApiCall } from './apiStatusActions';

export function loadTenantsSuccess(tenants) {
    return { type: types.LOAD_TENANTS_SUCCESS, tenants };
}

export function loadTenants() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return tenantsApi.getTenants().then(tenants => {
            dispatch(loadTenantsSuccess(tenants))
        }).catch(error => {
            throw error;
        });
    }
}

export function setApiKey(apiKey) {
    return function (dispatch) {
        dispatch({ type: types.SET_API_KEY, apiKey })
    }
}