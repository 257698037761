import React from 'react';

import ProfileMenu from './ProfileMenu';

const Header = () => {
    return (
        <div id="header">
            <div id="logo"></div>
            <ProfileMenu />

        </div>
    )
};

export default Header;