import * as types from './actionTypes';
import * as projectApi from '../../api/projectApi';
import { beginApiCall } from './apiStatusActions';

export function loadProjectsSuccess(projects) {
    return { type: types.LOAD_PROJECTS_SUCCESS, projects };
}

export function loadProjects() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return projectApi.getProjects().then(projects => {
            dispatch(loadProjectsSuccess(projects))
        }).catch(error => {
            throw error;
        });
    }
}