import React from 'react';
import { useAuth } from 'react-oidc-context';
import Paper from '../common/Paper';
import Table from '../common/Table';


const DashboardPage = () => { 
let auth = useAuth();
return (
    <> <h2>Dashboard</h2>
        <h1><b>Välkommen,</b> {auth.user.profile.given_name}</h1>
        <Paper>
            God dag.
        </Paper>
    </>
)};

export default DashboardPage;