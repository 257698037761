import React from 'react';
import {connect} from 'react-redux';
import * as testActions from '../../redux/actions/testActions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { toast } from "react-toastify";

class TestPage extends React.Component {
    componentDidMount() {
        this.props.actions.test().catch( error => {
            toast.error(error);
        });
    }

    render() {
        
        return (
        <div>
            <h1>Test</h1>
        </div>)
    }
}

TestPage.propTypes = {
    actions: PropTypes.object
};

function mapStateToProps() {
    return {
        
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(testActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);