import * as types from "./actionTypes";
import { beginApiCall } from "./apiStatusActions";
import * as supplyChainApi from "../../api/supplyChainApi";

function updateSupplierSuccess(savedSupplier) {
	return { type: types.UPDATE_SUPPLIER_SUCCESS, payload: savedSupplier };
}

function createSupplierSuccess(savedSupplier) {
	return { type: types.CREATE_SUPPLIER_SUCCESS, payload: savedSupplier };
}

function removeSupplierSuccess() {
	return { type: types.REMOVE_SUPPLIER_SUCCESS };
}

export function loadSupplyChainForProject(projectId) {
	return function (dispatch) {
		dispatch(beginApiCall());
		return supplyChainApi
			.getSupplyChainForProject(projectId)
			.then((supplyChain) => {
				dispatch({ type: types.LOAD_SUPPLY_CHAIN_SUCCESS, supplyChain });
				return supplyChain;
			})
			.catch((error) => {
				dispatch({ type: types.LOAD_SUPPLY_CHAIN_FAILED, error });
			});
	};
}

export function saveSupplier(supplyChainId, supplier) {
	return function (dispatch) {
		return supplyChainApi
			.saveSupplier(supplyChainId, supplier)
			.then((savedSupplier) => {
				supplier.id
					? dispatch(updateSupplierSuccess(savedSupplier))
					: dispatch(createSupplierSuccess(savedSupplier));
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function removeSupplier(supplyChainId, supplierId) {
	return function (dispatch) {
		return supplyChainApi
			.removeSupplier(supplyChainId, supplierId)
			.then(() => {
				dispatch(removeSupplierSuccess());
			})
			.catch((error) => {
				throw error;
			});
	};
}
