import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useAuth} from 'react-oidc-context';

function MainMenu() {
	const auth = useAuth();
		
if(!auth.isAuthenticated) {
    return <></>;
}

	return (
		<div id="main-menu">
			<nav>
				<NavLink to="/home">
					<FontAwesomeIcon icon={["fal", "home"]} />
					<p className="name">Hem</p>
				</NavLink>
				<NavLink to="/projects">
					<FontAwesomeIcon icon={["fal", "clipboard"]} />
					<p className="name">Projekt</p>
				</NavLink>
				<NavLink to="/supplychain">
					<FontAwesomeIcon icon={["fal", "sitemap"]} />
					<p className="name">Leverantörer</p>
				</NavLink>
				<NavLink to="/test">
					<FontAwesomeIcon icon={["fal", "vial"]} />
					<p className="name">Test</p>
				</NavLink>
				<NavLink to="/users">
					<FontAwesomeIcon icon={["fal", "users"]} />
					<p className="name">Personal</p>
				</NavLink>
				<NavLink to="/settings">
					<FontAwesomeIcon icon={["fal", "cog"]} />
					<p className="name">Inställningar</p>
				</NavLink>
			</nav>
		</div>
	);
}

export default MainMenu;
