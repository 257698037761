import { User } from 'oidc-client-ts';
import OidcConfig from '../oidc.config';

function getUser() {

    const oidcStorage = sessionStorage.getItem(`oidc.user:${OidcConfig.authority}:${OidcConfig.client_id}`)

    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        (config) => {
            let user = getUser();
            const { auth } = store.getState();

            if (user?.id_token != 'undefined') {
                config.headers.Authorization = `Bearer ${user.access_token}`;
            }

            if (auth?.apiKey) {
                config.headers['x-api-key'] = auth?.apiKey;
            }
            else {
                delete config.headers['x-api-key'];
            }

            return config;
        },
        (err) => Promise.reject(err)
    );
}