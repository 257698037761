import React from 'react';
import { connect } from 'react-redux';
import * as projectActions from '../../redux/actions/projectActions';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Paper from '../common/Paper';
import { NavLink, useParams } from 'react-router-dom';


function ProjectPage() {
    const { id } = useParams();
    
    return (
        <>
        <h2>Projekt</h2>
            <h1>Teasdstprojekt</h1>
            <nav>
                <NavLink to='/home'>Projekt</NavLink>
                <NavLink to={`/projects/${id}/suppliers`}>Leverantörer</NavLink>
            </nav>
            <Paper>
                {id}
            </Paper>
        </>)
}

ProjectPage.propTypes = {
    actions: PropTypes.object,
    projects: PropTypes.array,
    id: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        projects: state.projects
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(projectActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);