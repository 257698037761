import * as types from "./actionTypes";
import { beginApiCall } from "./apiStatusActions";
import * as supplierApi from "../../api/supplierApi";

export function getSupplier(supplierId) {
	return function (dispatch) {
		dispatch(beginApiCall());
		return supplierApi
			.getSupplier(supplierId)
			.then((supplier) => {
				dispatch({ type: types.LOAD_SUPPLIER_SUCCESS, supplier });
				return supplier;
			})
			.catch((error) => {
				dispatch({ type: types.LOAD_SUPPLIER_FAILED, error });
			});
	};
}