import * as testApi from '../../api/testApi';

export function testSuccess() {
}

export function test() {
    return function(dispatch) {
        return testApi.test().then(() => {
            dispatch(testSuccess())
        }).catch(error => {
            throw error;
        });
    }
}