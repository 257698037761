import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

function Modal(props) {
    const {children, onClose, show, allowClose = true} = props;

    if(!show) {
        return null;
    }

    return (
        <div className="modal-root">
            <div className="modal-container">
                {allowClose && <span className="close" onClick={onClose}>&times;</span>}
                {children}
            </div>
        </div>
    )
}

Modal.propTypes = {
    children: PropTypes.any
}

export default Modal;