import {
	USER_SIGNED_OUT,
	STORE_USER_ERROR,
	USER_EXPIRED,
	STORE_USER,
	LOADING_USER,
	SET_API_KEY,
  LOAD_TENANTS_SUCCESS
} from "../actions/actionTypes";

import initialState from "./initialState";

export default function (state = initialState.auth, action) {
	switch (action.type) {
		case STORE_USER:
			return {
				...state,
				isLoadingUser: false,
				user: action.payload,
			};
		case LOADING_USER:
			return {
				...state,
				isLoadingUser: true,
			};
		case SET_API_KEY:
			return {
				...state,
				apiKey: action.apiKey,
			};
		case LOAD_TENANTS_SUCCESS: {
			if (state.apiKey) {
				return state;
			}

			return { ...state, apiKey: action.tenants[0].apiKey };
		}
		case USER_EXPIRED:
		case STORE_USER_ERROR:
		case USER_SIGNED_OUT:
			return {
				...state,
				user: null,
				isLoadingUser: false,
			};
		default:
			return state;
	}
}
