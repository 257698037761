import React from "react";
import { connect } from "react-redux";
import * as projectActions from "../../redux/actions/projectActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Table from "../common/Table";
import Paper from "../common/Paper";
import { Link, withRouter } from "react-router-dom";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import strings from "../../misc/strings";

class ProjectsPage extends React.Component {
	componentDidMount() {
		this.props.actions.loadProjects().catch((error) => {
			toast.error(error.toString());
		});
	}

	redirectToProject(id) {
		this.props.history.push("/projects/" + id);
	}

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<h1>
					{strings.projectsPage.title}
					{/* {this.props.apiCalls} */}
				</h1>
				{this.props.loading ? (
					<Spinner />
				) : (
					<Paper>
						<Table
							columns={[
								{
									id: "name",
									title: strings.projectsPage.tableColumnName,
									template: (p) => (
										<>
											<span>{p.name}</span>
											<br />
											<span className="light-gray">
												{p.description ? p.description : "-"}
											</span>
										</>
									),
									sortable: false,
								},
								{
									id: "startDate",
									title: strings.projectsPage.tableColumnStartDate,
									template: (p) => (
										<span>
											{DateTime.fromISO(p.startDate).toLocaleString(
												DateTime.DATE_MED
											)}
										</span>
									),
									sortable: false,
								},
								{
									id: "endDate",
									title: strings.projectsPage.tableColumnEndDate,
									template: (p) => (
										<span>
											{DateTime.fromISO(p.startDate).toLocaleString(
												DateTime.DATE_MED
											)}
										</span>
									),
									sortable: false,
								},
							]}
							rows={this.props.projects}
							onRowClick={this.redirectToProject.bind(this)}
							actions={[
								{
									icon: "trash-alt",
									tooltip: "Edit",
									onClick: (event, rowData) => alert("Edit?"),
								},
							]}
						></Table>
					</Paper>
				)}
			</>
		);
	}
}

ProjectsPage.propTypes = {
	actions: PropTypes.object,
	projects: PropTypes.array,
	history: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	apiCalls: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
	return {
		projects: state.projects,
		loading: state.apiCallsInProgress > 0,
		apiCalls: state.apiCallsInProgress,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(projectActions, dispatch),
	};
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ProjectsPage)
);
