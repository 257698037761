import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { REHYDRATE } from 'redux-persist'

function actionTypeEndsInSuccess(type) {
    return type.substring(type.length - 8) === "_SUCCESS";
}

function actionTypeEndsInFailed(type) {
    return type.substring(type.length - 7) === "_FAILED";
}

export default function apiCallStatusReducer(
    state = initialState.apiCallsInProgress,
    action
) {
    if (action.type == REHYDRATE) {
        return "0";
    }

    if (action.type == types.BEGIN_API_CALL) {
        return state + 1;
    }

    if (actionTypeEndsInSuccess(action.type) || actionTypeEndsInFailed(action.type)) {
        return state - 1;
    }

    return state;
}