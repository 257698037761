export const TEST = 'TEST';

export const BEGIN_API_CALL = 'BEGIN_API_CALL';

export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const LOAD_PROJECTS_FAILED = 'LOAD_PROJECTS_FAILED';

export const LOGOUT = 'LOGOUT';
export const STORE_USER = 'STORE_USER';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_EXPIRED = 'USER_EXPIRED';
export const STORE_USER_ERROR = 'STORE_USER_ERROR'
export const LOADING_USER = 'LOADING_USER';

export const LOAD_TENANTS = 'LOAD_TENANTS';
export const LOAD_TENANTS_SUCCESS = 'LOAD_TENANTS_SUCCESS';
export const LOAD_TENANTS_FAILED = 'LOAD_TENANTS_FAILED';
export const SET_API_KEY = 'SET_API_KEY';

export const LOAD_SUPPLY_CHAIN = 'LOAD_SUPPLY_CHAIN';
export const LOAD_SUPPLY_CHAIN_SUCCESS = 'LOAD_SUPPLY_CHAIN_SUCCESS';
export const LOAD_SUPPLY_CHAIN_FAILED = 'LOAD_SUPPLY_CHAIN_FAILED';
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS';
export const REMOVE_SUPPLIER_SUCCESS = 'REMOVE_SUPPLIER_SUCCESS';
export const LOAD_SUPPLIER_SUCCESS = 'LOAD_SUPPLIER_SUCCESS';
export const LOAD_SUPPLIER_FAILED = 'LOAD_SUPPLIER_FAILED';

export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';

