import React from 'react';
import * as PropTypes from 'prop-types';

const Paper = ({children}) => {
    return (
        <div className="paper-root">
            {children}
        </div>
    )
};

Paper.propTypes = {
    children: PropTypes.any
}

export default Paper;