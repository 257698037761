export default {
  auth: {
    user: null,
    apiKey: null,
    isLoadingUser: false
  },
  projects: [],
  apiCallsInProgress: 0,
  customers: [],
  tenants: []
};
