import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import strings from "../../misc/strings";
import { useAuth } from 'react-oidc-context';

function Login() {
	const auth = useAuth();
	const user = useSelector((state) => state.auth.user);

	function login() {
		console.log("WOOBLE")
		auth.signinRedirect();
	}

  if(!user) {
    //login
  }

	return user ? (
		<Redirect to={"/"} />
	) : (
		<>
			<h1>{strings.loginPage.title}</h1>

			<button onClick={() => login()}>{strings.loginPage.redirectButton}</button>
		</>
	);
}

export default Login;
