import { handleResponse, handleError } from "./apiUtils";
const axios = require("axios");

export function getSupplier(id) {
	return axios({
		method: "get",
		url: `${process.env.API_URL}/v1/suppliers/${id}/`,
	})
		.then(handleResponse)
		.catch(handleError);
}