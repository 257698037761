import { handleResponse, handleError } from "./apiUtils";
const axios = require("axios");

export function getSupplyChainForProject(projectId) {
	return axios({
		method: "get",
		url: `${process.env.API_URL}/v1/projects/${projectId}/supplychain/`,
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveSupplier(supplyChainId, supplier) {
	return axios({
		method: supplier.id ? "put" : "post",
		url:
			`${process.env.API_URL}/v1/supplychains/${supplyChainId}/suppliers/` +
			(supplier.id ? `${supplier.id}/` : ""),
		data: supplier,
	})
		.then(handleResponse)
		.catch(handleError);
}

export function removeSupplier(supplyChainId, supplierId) {
	return axios({
		method: "delete",
		url: `${process.env.API_URL}/v1/supplychains/${supplyChainId}/suppliers/${supplierId}`
	})
		.then(handleResponse)
		.catch(handleError);
}
