import React from "react";
import * as TenantsActions from "../redux/actions/tenantsActions";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { useAuth } from "react-oidc-context";

const TenantProvider = ({
	store,
	children
}) => {
	var auth = useAuth();
	const tenants = useSelector(state => state.tenants);
	const stateAuth = useSelector(state => state.auth);
	
	if(auth.isAuthenticated && tenants.length === 0) {
		store.dispatch(TenantsActions.loadTenants());
	}
	
	// useEffect(() => {
		
	// }, [store]);
	return ((!auth.isAuthenticated || stateAuth.apiKey) ? React.Children.only(children) : (<div>No tenant set</div>));
}

TenantProvider.propTypes = {
	store: PropTypes.object.isRequired,
	children: PropTypes.any
};

export default TenantProvider;