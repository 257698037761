import React from "react";
import PropTypes from "prop-types";
import TextInput from "../common/TextInput";
import Countries from "../../misc/countries";
import SelectInput from "../common/SelectInput";
import strings from "../../misc/strings";

const SupplierForm = ({
	supplier,
	errors = {},
	onChange,
	onSave,
	saving = false,
}) => {
	return (
		<form onSubmit={onSave}>
			<h2>
				{supplier.id
					? strings.suppliersPage.supplierForm.titleEdit
					: strings.suppliersPage.supplierForm.titleAdd}
			</h2>
			<TextInput
				name="identifier"
				label={strings.suppliersPage.supplierForm.identifier}
				value={supplier.identifier}
				onChange={onChange}
				error={errors.identifier}
			/>
			<SelectInput
				name="countryCode"
				label={strings.suppliersPage.supplierForm.country}
				value={supplier.countryCode}
				onChange={onChange}
				error={errors.countryCode}
				options={Object.keys(Countries).map((key) => ({
					value: key,
					text: strings.countries[key],
				}))}
			/>
			<TextInput
				name="name"
				label={strings.suppliersPage.supplierForm.name}
				value={supplier.name}
				onChange={onChange}
				error={errors.name}
			/>
			<button type="submit" disabled={saving}>
				{saving ? strings.global.buttonSaving : strings.global.buttonSave}
			</button>
		</form>
	);
};

SupplierForm.propTypes = {
	supplier: PropTypes.object.isRequired,
	errors: PropTypes.object,
	onSave: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	saving: PropTypes.bool,
};

export default SupplierForm;
