import React from 'react';
import { createPortal } from 'react-dom';
import useConfirm from '../../hooks/useConfirm';
import strings from "../../misc/strings";

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();

    const portalElement = document.getElementById('portal');
    const component = confirmState.show ? (
        <div className="portal-overlay">
            <div className="confirm-dialog">
                <p>{confirmState?.text && confirmState.text}</p>
                <div className="confirm-dialog__footer">
                    <button className="btn" onClick={onConfirm}>
                    {strings.global.confirmationDialog.buttonYes}
                    </button>
                    <button className="btn" onClick={onCancel}>
                    {strings.global.confirmationDialog.buttonCancel}
                    </button>
                </div>
            </div>
        </div>
    ) : null;

    return createPortal(component, portalElement);
};

export default ConfirmDialog;