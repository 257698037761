import { handleResponse, handleError } from "./apiUtils";
const axios = require('axios');

const baseUrl = process.env.API_URL + "/v1/test/";

export function test() {
return axios({
  method: 'get',
  url: baseUrl,
  headers: {'x-api-key': '98D92BF3-0620-4CC4-9C7D-78BC94DD4C55' }
}).then(handleResponse)
.catch(handleError);
}