import React from 'react';
import * as PropTypes from 'prop-types';

const PageHead = ({children, title, subtitle}) => {

    return (
        <div className="page-head">
            <div className='page-head-title-content'>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            </div>
            <div className='page-head-content'>
                {children}
            </div>
        </div>
    )
};

PageHead.propTypes = {
    children: PropTypes.any
}

export default PageHead;