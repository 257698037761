import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function tenantsReducer(state = initialState.tenants, action) {
  switch (action.type) {
    case types.LOAD_TENANTS_SUCCESS: {
      return action.tenants;
    }
    default:
      return state;
  }
}