import * as types from "../actions/actionTypes";

export default function supplyChainReducer(state = {}, action) {
  switch (action.type) {
    case types.ADD_SUPPLIER: {
      return Object.assign({}, state, { name: action.supplier.identifier });
    }
    default:
      return state;
  }
}