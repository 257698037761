import React from 'react';
import { Children, cloneElement } from 'react';
import * as PropTypes from 'prop-types';

export function Breadcrumbs({ children, separator }) {
    const arrayChildren = Children.toArray(children);

    return (
        <ul className='breadcrumb-list'
            style={{
                listStyle: "none",
                display: "flex",
            }}
        >
            {Children.map(arrayChildren, (child, index) => {
                const isLast = index === arrayChildren.length - 1;

                if (!isLast && !child.props.link) {
                    throw new Error(
                        `BreadcrumbItem child no. ${index + 1}
                should be passed a 'link' prop`
                    )
                }

                return (
                    <>
                        {child.props.link ? (
                            <a
                                href={child.props.link}
                                style={{
                                    display: "inline-block",
                                    textDecoration: "none",
                                }}
                            >
                                <div style={{ marginRight: "5px" }}>
                                    {cloneElement(child, {
                                        isLast,
                                    })}
                                </div>
                            </a>
                        ) : (
                            <div style={{ marginRight: "5px" }}>
                                {cloneElement(child, {
                                    isLast,
                                })}
                            </div>
                        )}
                        {!isLast && (
                            <div style={{ marginRight: "5px" }}>
                                {separator}
                            </div>
                        )}
                    </>
                );
            })}
        </ul>
    );
}

export function BreadcrumbItem({ isLast, children }) {
    return (
        <li
            style={{
                color: isLast ? "black" : "blue",
            }}
        >
            {children}
        </li>
    );
}

Breadcrumbs.propTypes = {
    children: PropTypes.any,
    separator: PropTypes.string
}