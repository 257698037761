import { handleResponse, handleError } from "./apiUtils";
const axios = require('axios');

const baseUrl = process.env.API_URL + "/v1/users/";

export function getUsers() {
  return axios({
    method: 'get',
    url: baseUrl
  }).then(handleResponse)
    .catch(handleError);
}
