import { combineReducers } from "redux";
import supplyChain from "./supplyChainReducer";
import projects from "./projectReducer";
import auth from "./authReducer";
import apiCallsInProgress from './apiStatusReducer';
import tenants from './tenantsReducer';
import * as ActionTypes from '../actions/actionTypes';

const appReducer = combineReducers({
  apiCallsInProgress,
  projects,
  supplyChain,
  auth,
  tenants
});

const rootReducer = (state, action) => {
  if (state !== undefined && action.type === ActionTypes.LOGOUT) {
    return rootReducer(undefined, action);
  }

  return appReducer(state, action)
}

export default rootReducer;