import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router";
import * as supplyChainActions from "../../redux/actions/supplyChainActions";
import { bindActionCreators } from "redux";
import Spinner from "../common/Spinner";
import Table from "../common/Table";
import Paper from "../common/Paper";
import Modal from "../common/Modal";
import Button from "../common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SupplierForm from "./SupplierForm";
import { toast } from "react-toastify";
import strings from "../../misc/strings";
import useConfirm from "../../hooks/useConfirm";
import PageHead from "../common/PageHead";
import { withRouter } from "react-router-dom";
import { BreadcrumbItem, Breadcrumbs } from "../common/Breadcrumbs";

const newSupplier = {
	parentId: null,
	identifier: "",
	countryCode: "SE",
	name: "",
};

const flatten = (node) => [{ parentId: null, ...node }].concat(
	...node.suppliers.map((child) => flatten({ parentId: node.id, ...child }))
);

function getSuppliers(supplyChain) {
	if (!supplyChain) {
		return [];
	}

	if (!supplyChain.suppliers?.length) {
		return [];
	}

	let suppliers = supplyChain.suppliers?.length
		? supplyChain.suppliers.map((s) => flatten(s)).flat()
		: [];

	// suppliers = suppliers.filter(
	// 	(supplier, position, arr) =>
	// 		arr.map((s) => s["identifier"]).indexOf(supplier["identifier"]) == position
	// );
	return suppliers;
}

function getPath(suppliers, id) {
	if(suppliers.length == 0) {
		return []
	}

	let supplier = suppliers.find(s => s.id == id);
	let path = [supplier];

	if (supplier.parentId != null) {
		path = getPath(suppliers, supplier.parentId).concat(path);
	}

	return path;
}

function getGradeBadge(grade) {
	switch (grade) {
		case 0:
			return <span className="badge secondary"><FontAwesomeIcon icon={["fa", "question-circle"]} /> Ofullständig</span>;
		case 1:
			return <span className="badge success"><FontAwesomeIcon icon={["fa", "check-circle"]} /> OK</span>;
		case 2:
			return <span className="badge info"> Observera</span>;
		case 3:
			return <span className="badge warning"><FontAwesomeIcon icon={["fa", "exclamation-triangle"]} /> Undersökning behövs</span>;
		case 4:
			return <span className="badge danger"><FontAwesomeIcon icon={["fa", "exclamation-circle"]} /> Varning</span>;
	}
}

function SuppliersPage(props) {
	const { actions, loading, history } = props;
	const { projectId, supplierId } = useParams();
	const [supplyChain, setSupplyChain] = useState({ id: null });
	const [showAdd, setShowAdd] = useState(false);
	const [saving, setSaving] = useState(false);
	const [supplier, setSupplier] = useState({ ...props.supplier });
	const [parent, setParent] = useState(supplierId ?? null);
	const [errors, setErrors] = useState({ hasErrors: false });
	const { confirm } = useConfirm();

	const handleDelete = async (supplier) => {
		const isConfirmed = await confirm(strings.suppliersPage.confirmRemoveMessage.replace('[name]', supplier.name));

		if (isConfirmed) {
			actions.removeSupplier(supplyChain.id, supplier.id)
				.then(() => actions.loadSupplyChainForProject(projectId)
					.then((result) => setSupplyChain(result)));
		}
	};

	useEffect(() => {
		actions
			.loadSupplyChainForProject(projectId)
			.then((result) => {
				setSupplyChain(result)
			});
	}, []);

	function handleChange(event) {
		const { name, value } = event.target;
		setSupplier((prevSupplier) => ({
			...prevSupplier,
			[name]: value,
		}));
	}

	function handleSelect(ids) {
		console.log(ids);
	}

	function handleSave(event) {
		event.preventDefault();

		setErrors({
			name: !supplier.name
				? strings.suppliersPage.supplierForm.errorName
				: null,
			identifier: !supplier.identifier
				? strings.suppliersPage.supplierForm.errorIdentifier
				: null,
			countryCode: !supplier.countryCode
				? strings.suppliersPage.supplierForm.errorCountry
				: null,
		});

		if (!supplier.name || !supplier.identifier || !supplier.countryCode) {
			return;
		}

		setSaving(true);
		actions
			.saveSupplier(supplyChain.id, { ...supplier, parentId: parent })
			.then(() => {
				toast.success(strings.suppliersPage.supplierForm.saveSuccessMessage);
				setShowAdd(false);
				setSaving(false);
				actions.loadSupplyChainForProject(projectId)
					.then((result) => setSupplyChain(result));
			})
			.catch(() => {
				toast.error(strings.suppliersPage.supplierForm.saveErrorMessage);
				setSaving(false);
			});
	}

	function redirectToSupplier(supplier) {
		history.push(`/suppliers/${supplier.id}`);
	}

	function filterByParent(supplier) {
		let test = getPath(getSuppliers(supplyChain), supplier);
		setParent(supplier);
	}

	return (
		<>
			<Modal show={showAdd} onClose={() => setShowAdd(false)}>
				<SupplierForm
					supplier={supplier}
					errors={errors}
					onChange={handleChange}
					onSave={handleSave}
					saving={saving}
				/>
			</Modal>
			<PageHead title={strings.suppliersPage.title} subtitle="Projektets alla leverantörer">
				<Button onClick={() => setShowAdd(true)}>
					<FontAwesomeIcon icon={["fal", "plus"]} /> Lägg till leverantör
				</Button>&nbsp;
				<Button onClick={() => setShowAdd(true)}>
					<FontAwesomeIcon icon={["fal", "plus"]} /> Bjud in deltagare
				</Button>
			</PageHead>
			{parent && <Breadcrumbs separator=">">
				<BreadcrumbItem link={`/projects/${projectId}/suppliers/`}>
				Huvudentreprenör
				</BreadcrumbItem>
				{getPath(getSuppliers(supplyChain), parent)?.map(p => <BreadcrumbItem key={p.id} link={`/projects/${projectId}/suppliers/${p.id}`}>
					{p.name}
				</BreadcrumbItem>)}
			</Breadcrumbs>}
			{loading ? (
				<Spinner />
			) : (
				<Paper>
					<Table
						selectable={true}
						onSelect={handleSelect}
						columns={[
							{
								id: "name",
								title: strings.suppliersPage.listSupplierName,
								template: (p) => (
									<>
										<span>{p.name}</span>
									</>
								),
								sortable: false,
							},
							{
								id: "status",
								title: strings.suppliersPage.listSupplierStatus,
								template: (p) => <>{getGradeBadge(p.grade)}</>,
								sortable: false,
							}
						]}
						onRowClick={filterByParent.bind(this)}
						actions={[
							{
								icon: "certificate",
								tooltip: "Edit",
								onClick: redirectToSupplier.bind(this),
							},
							{
								icon: "trash-alt",
								tooltip: "Delete",
								onClick: handleDelete.bind(this),
							},
						]}
						rows={getSuppliers(supplyChain).filter(s => s.parentId == parent)}
					></Table>
				</Paper>
			)}
		</>
	);
}

SuppliersPage.propTypes = {
	loading: PropTypes.bool.isRequired,
	supplier: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		loading: state.apiCallsInProgress > 0,
		supplier: newSupplier,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(supplyChainActions, dispatch),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuppliersPage));


