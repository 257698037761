import LocalizedStrings from "react-localization";
import countries from "./countries";

let strings = new LocalizedStrings({
	en: {
		global: {
			buttonSave: "Save",
			buttonSaving: "Saving...",
            profileMenuGreeting: "Hi, [name]!",
            confirmationDialog: {
                buttonYes: "Yes",
                buttonCancel: "Cancel"
            }
		},
        loginPage: {
            title: "You need to be logged in to view this page!",
            redirectButton: "Click here if yo're not automatically redirected"
        },
        missingPage: {
            title: "Page missing!",
            message: "It's probably [name]'s fault."
        },
		suppliersPage: {
			title: "Suppliers",
			listSupplierName: "Name",
			listSupplierStatus: "Status",
            supplierForm: {
				titleAdd: "Add supplier",
				titleEdit: "Edit supplier",
				identifier: "Organization number",
				country: "Country",
				name: "Company name",
                errorName: "Company name cannot be empty",
                errorIdentifier: "Organization number cannot be empty",
                errorCountry: "Country must be selected",
                saveSuccessMessage: "Supplier added!",
                saveErrorMessage: "Error adding supplier"
			},
            confirmRemoveMessage: 'Are you sure that you want to remove the supplier \'[name]\'?'
		},
        projectsPage: {
            title: "Projects",
            tableColumnName: "Name",
            tableColumnStartDate: "Starts",
            tableColumnEndDate: "Ends",
            tableColumnOptions: "Options",
        },
        usersPage: {
            title: "Users",
            tableColumnName: "Name",
            tableColumnOptions: "Options",
        },
		countries: {
			...countries,
		},
	},
	se: {
		global: {
			buttonSave: "Spara",
			buttonSaving: "Sparar...",
            profileMenuGreeting: "Hej, [name]!",
            confirmationDialog: {
                buttonYes: "Ja",
                buttonCancel: "Avbryt"
            }
		},
        loginPage: {
            title: "Du behöver vara inloggad för att visa denna sidan!",
            redirectButton: "Tryck här om du inte skickas vidare automatiskt"
        },
        missingPage: {
            title: "Sidan saknas!",
            message: "Det är säkert [name]s fel."
        },
		suppliersPage: {
			title: "Leverantörer",
			listSupplierName: "Namn",
            listSupplierStatus: "Status",
			supplierForm: {
				titleAdd: "Lägg till leverantör",
				titleEdit: "Redigera leverantör",
				identifier: "Organisationsnummer",
				country: "Land",
				name: "Företagsnamn",
                errorName: "Företagsnamn får inte vara tomt",
                errorIdentifier: "Organisationsnummer får inte vara tomt",
                errorCountry: "Land måste vara valt",
                saveSuccessMessage: "Leverantör skapad!",
                saveErrorMessage: "Leverantören kunde inte skapas!"
			},
            confirmRemoveMessage: 'Är du säker på att du vill ta bort leverantören \'[name]\'?'
		},
        projectsPage: {
            title: "Projekt",
            tableColumnName: "Namn",
            tableColumnStartDate: "Startar",
            tableColumnEndDate: "Slutar",
            tableColumnOptions: "Alternativ",
        },
        usersPage: {
            title: "Användare",
            tableColumnName: "Namn",
            tableColumnOptions: "Alternativ",
        },
        countries: {
			...countries,
            SE: "Sverige"
		},
	},
});

export default strings;
