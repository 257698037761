import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as tenantsActions from "../../redux/actions/tenantsActions";
import strings from "../../misc/strings";
import { useHistory } from 'react-router-dom'
import { useAuth } from "react-oidc-context";

const ProfileMenu = () => {
	const dropdownRef = useRef(null);
	const [isActive, setIsActive] = useState(false);
	const onClick = () => setIsActive(!isActive);
	const tenants = useSelector((state) => state.tenants);
	const dispatch = useDispatch();
	const history = useHistory();
	const auth = useAuth();

	function setTenant(apiKey) {
		dispatch(tenantsActions.setApiKey(apiKey));
		history.push('/');
		setIsActive(false);
	}

	return (
		<div className="profile-container">
			<div className="menu-container">
				<div onClick={onClick} className="menu-trigger">
					<img
						src="https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/df/df7789f313571604c0e4fb82154f7ee93d9989c6.jpg"
						alt="User avatar"
					/>
					<span>
						{auth.isAuthenticated && (
							<p>
								{strings.global.profileMenuGreeting.replace(
									"[name]",
									auth.user.profile.given_name
								)}
							</p>
						)}
					</span>
				</div>
				<nav
					ref={dropdownRef}
					className={`menu ${isActive ? "active" : "inactive"}`}
				>
					<ul>
						{tenants.map((t) => (
							<li key={t.apiKey}>
								<a href="#" onClick={setTenant.bind(this, t.apiKey)}>
									{t.name}
								</a>
							</li>
						))}

						<li>
							<Link to="signout-oidc">Logga ut</Link>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

ProfileMenu.propTypes = {
}

export default ProfileMenu;
