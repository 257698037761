import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "react-oidc-context";

function ProtectedRoute({ component: Component, ...rest }) {
	const auth = useAuth();

	switch (auth.activeNavigator) {
		case "signinSilent":
			return <div>Signing you in...</div>;
		case "signoutRedirect":
			return <div>Signing you out...</div>;
	}

	if (auth.isLoading) {
		return <div>Loading...</div>;
	}

	if (auth.error) {
		return <div>Oops... {auth.error.message}</div>;
	}

	if (auth.isAuthenticated) {
		return <Route {...rest} component={Component} />;
	}

	return <Redirect to={"/login"} />;
}

ProtectedRoute.propTypes = {
	children: PropTypes.array,
	component: PropTypes.any.isRequired,
};

export default ProtectedRoute;
