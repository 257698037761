import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Table from "../common/Table";
import Paper from "../common/Paper";
import { withRouter } from "react-router-dom";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import strings from "../../misc/strings";
import * as userActions from '../../redux/actions/userActions';

class UsersPage extends React.Component {
	componentDidMount() {
		this.props.actions.loadUsers().then(result => {
			this.setState({users: result.users});
		}).catch((error) => {
			toast.error(error.toString());
		});
	}

	redirectToUser(id) {
		this.props.history.push("/users/" + id);
	}

	constructor(props) {
		super(props);
		this.state = {users: []};
	}

	render() {
		return (
			<>
				<h1>
					{strings.usersPage.title}
				</h1>
				{this.props.loading ? (
					<Spinner />
				) : (
					<Paper>
						<Table
							columns={[
								{
									id: "name",
									title: strings.usersPage.tableColumnName,
									template: (p) => (
										<>
											<span>{p.firstname} {p.lastname}</span>
										</>
									),
									sortable: false,
								}
							]}
							rows={this.state.users}
							onRowClick={this.redirectToUser.bind(this)}
							actions={[
								{
									icon: "user-edit",
									tooltip: "Edit",
									onClick: (event, rowData) => alert("Edit?"),
								},
							]}
						></Table>
					</Paper>
				)}
			</>
		);
	}
}

UsersPage.propTypes = {
	actions: PropTypes.object,
	users: PropTypes.array,
	history: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		users: state.users,
		loading: state.apiCallsInProgress > 0,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(userActions, dispatch),
	};
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(UsersPage)
);
