import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as supplierActions from "../../redux/actions/supplierActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Paper from "../common/Paper";
import { NavLink, useParams } from "react-router-dom";
import PageHead from "../common/PageHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../common/Spinner";
import * as flags from 'flag-icons';

function getGradeBadge(grade) {
	switch (grade) {
		case 0:
			return <span className=" "><FontAwesomeIcon icon={["fa", "question-circle"]} /> Ofullständig</span>;
		case 1:
			return <span className=" "><FontAwesomeIcon icon={["fa", "check-circle"]} /> Godkänd</span>;
		case 2:
			return <span className=" "> Observera</span>;
		case 3:
			return <span className=" "><FontAwesomeIcon icon={["fa", "exclamation-triangle"]} /> Undersökning behövs</span>;
		case 4:
			return <span className=" "><FontAwesomeIcon icon={["fa", "exclamation-circle"]} /> Varning</span>;
	}
}

function getSupplierType(type) {
	switch (type) {
		case 1: return 'Aktiebolag';
		case 2: return 'Enskild firma';
		case 3: return 'Handelsbolag';			
		case 4: return 'Kommanditbolag';
		default: return '-'
	}
}

function SupplierPage({ actions }) {
	const { supplierId } = useParams();
	const [supplier, setSupplier] = useState(null);

	useEffect(() => {
		actions.getSupplier(supplierId).then((result) => {
			setSupplier(result);
		});
	}, []);

	if (!supplier) {
		return (<Spinner />);
	}
	return (
		<>
			<PageHead title={supplier?.name} subtitle={
				<p>
					{supplier.identifier}
					<span className={`fi fi-${supplier.countryCode.toLowerCase()}`}></span>
				</p>
			}></PageHead>
			{/* <nav>
                <NavLink to='/home'>Projekt</NavLink>
                <NavLink to={`/projects/${projectId}/suppliers`}>Leverantörer</NavLink>
            </nav> */}
			<Paper>
				<div style={{ padding: '3rem' }}>
					<div>
						{getGradeBadge(supplier.grade)}
					</div>
					<br />
					<div>
						<div className="container">
							<div className="row">
								<div className="col-sm">
									<span className='header-2'>Översikt</span>
								</div>
							</div>
							<div className='row'>
								<div className='col-sm'>
									<span className='lt-small'>Företagsform</span>{getSupplierType(supplier.type)}
								</div>
								<div className='col-sm'>
									<span className='lt-small'>Antal anställda</span>{supplier.numEmployees}
								</div>
								<div className='col-sm'>
									<span className='lt-small'>Registreringsår</span>{new Date(supplier.registrationDate).getFullYear()}
								</div>
							</div><br />
							<div className='row'>
								<div className='col-sm'>
									<span className='lt-small'>Omsättning</span>{supplier.netTurnover} tkr
								</div>
								<div className='col-sm'>
									<span className='lt-small'>Status</span>{supplier.active ? 'Aktiv' : 'Inaktiv'}
								</div>
								<div className='col-sm'>
									<span className='lt-small'>Res. e. fin</span>{supplier.profitAfterTax}
								</div>

							</div><br />
							<div className="row">
								<div className='col-sm'>
									<span className='lt-small'>Bransch</span>{supplier.industryText}
								</div>
								<div className='col-sm'>
									<span className='lt-small'>Årets resultat</span>{supplier.netProfit} tkr
								</div>
								<div className='col-sm'>
									<span className='lt-small'>Kollektivavtal</span>Uppgifter saknas
								</div>

							</div><br />
							<div className="row">
								<div className='col-sm'>
									<span className='lt-small'>Summa tillgångar</span>{supplier.sumAssets}
								</div>
							</div>
						</div>
						<br />
						<div>
							<div className="container">
								<div className="row">
									<div className="col-sm">
										<span className='header-2'>Skatteverket</span>
									</div>
								</div><br />
								<div className="row">
									<div className='col-sm'>
										<span className='lt-small'>Godkänd för F-skatt</span>{supplier.registeredForFTax ? "Ja" : "Nej"}
									</div>
									<div className='col-sm'>
										<span className='lt-small'>Registrerad som arbetsgivare</span>{supplier.registeredForEmployerTax ? "Ja" : "Nej"}
									</div>
								</div><br />
								<div className="row">
									<div className='col-sm'>
										<span className='lt-small'>Registrerad för Moms</span>{supplier.registeredForVAT ? "Ja" : "Nej"}
									</div>
									<div className='col-sm'>
										<span className='lt-small'>Beslutade arbetsgivaravgifter</span>{supplier.employerFee}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Paper>
		</>
	);
}

SupplierPage.propTypes = {
	loading: PropTypes.bool.isRequired,
	actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		loading: state.apiCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(supplierActions, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierPage);