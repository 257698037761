import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {useAuth} from 'react-oidc-context';

function SignoutOidc() {
  const auth = useAuth();
  const history = useHistory()
  useEffect(() => {
auth.removeUser();
    // async function signoutAsync() {
    //   await signoutRedirectCallback()
    //   history.push('/')
    // }
    // signoutAsync()
  }, [history])

  return (
    <div>
      Redirecting...
    </div>
  )
}

export default SignoutOidc