import React from "react";
import { render } from "react-dom";
import App from "./components/App";
import "./index.css";
import { Settings } from "luxon";
import { store } from './redux/store'
import axios from "axios";
import setupAxios from "./redux/setupAxios";

import { AuthProvider } from 'react-oidc-context';

import OidcConfig from './oidc.config';
import { ConfirmContextProvider } from './store/ConfirmContextProvider';
import ConfirmDialog from './components/common/ConfirmDialog';


setupAxios(axios, store);

Settings.defaultLocale = "sv-SE";

render(
    // {/* <PersistGate loading={null} persistor={persistor}> */}
    // <ReduxProvider store={store}>
    <ConfirmContextProvider>
        <AuthProvider {...OidcConfig}>
            <App />
            <ConfirmDialog/>
        </AuthProvider>
    </ConfirmContextProvider>
    // </ReduxProvider>,
    , document.getElementById("app-container")
);

if (module.hot) {
    console.log("accepting hot");
    module.hot.accept();
}