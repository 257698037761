import * as types from './actionTypes';
import * as projectApi from '../../api/userApi';
import { beginApiCall } from './apiStatusActions';

export function loadUsersSuccess(users) {
    return { type: types.LOAD_USERS_SUCCESS, users };
}

export function loadUsers() {
    return function (dispatch) {
        dispatch(beginApiCall());
        return projectApi.getUsers().then(result => {
            dispatch(loadUsersSuccess(result));
            return result;
        }).catch(error => {
            throw error;
        });
    }
}