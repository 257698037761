import React from "react";
import * as PropTypes from "prop-types";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Table extends React.Component {
	static defaultProps = {
		idKey: "id",
		columns: [],
		rows: [],
		selectable: false,
		selected: [],
		actions: [],
		noResultTemplate: (cols) => {
			return (
				<tbody>
					<tr>
						<td colSpan={cols} style={{ textAlign: "center" }}>
							Sorry, we could not find any data!
						</td>
					</tr>
				</tbody>
			);
		},
	};

	renderTableHead() {
		const {
			columns,
			rows,
			idKey,
			onSelectAll,
			selectAllTemplate,
			selected,
			selectColumnTemplate,
			actions,
		} = this.props;
		const rowIds = rows.map((r) => r[idKey]);

		return (
			<thead>
				<tr>
					{selectColumnTemplate ? (
						<td onClick={onSelectAll ? onSelectAll.bind(null, rowIds) : null}>
							{selectAllTemplate &&
								selectAllTemplate(
									rowIds.length > 0 &&
										rowIds.every((id) => selected.indexOf(id) > -1)
								)}
						</td>
					) : null}
					{columns.map((c) => (
						<th key={c.id}>{c.title}</th>
					))}
					{actions ? actions.length > 0 ? <td></td> : null : null}
				</tr>
			</thead>
		);
	}

	renderTableBody() {
		const { rows, columns, idKey, selectColumnTemplate, selected, actions } =
			this.props;

		return (
			<tbody>
				{rows.map((row) => {
					return (
						<tr
							className={this.handleRowClick && "clickable"}
							key={row.id}
							onClick={this.handleRowClick.bind(this, row[idKey])}
						>
							{selectColumnTemplate ? (
								<td>
									{selectColumnTemplate(selected.indexOf(row[idKey]) > -1)}
								</td>
							) : null}
							{columns.map((c) => (
								<td key={`td-${row[idKey]}-${c.id}`}>
									{c.template ? c.template(row) : row[c.id]}
								</td>
							))}
							{actions ? (
								actions.length > 0 ? (
									<td
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										{actions.map((a, i) => (
											<Button key={`action_${row.id}_${i}`} className="icon show-on-hover" onClick={() => {a.onClick(row)}}>
												<FontAwesomeIcon icon={["fal", a.icon]} />
											</Button>
										))}
									</td>
								) : null
							) : null}
						</tr>
					);
				})}
			</tbody>
		);
	}

	handleRowClick(rowId) {
		const { onRowClick } = this.props;
		let selection = window.getSelection().toString();

		if (selection.length <= 0) {
			onRowClick && onRowClick(rowId);
		}
	}

	render() {
		return (
			<table>
				{this.renderTableHead()}
				{this.renderTableBody()}
			</table>
		);
	}
}
Table.propTypes = {
	columns: PropTypes.any,
	rows: PropTypes.any,
	selectable: PropTypes.bool,
	selected: PropTypes.array,
	selectColumnTemplate: PropTypes.func,
	onSelectAll: PropTypes.func,
	noResultTemplate: PropTypes.func,
	selectAllTemplate: PropTypes.any,
	actions: PropTypes.array,
};

export default Table;
