import React from "react";
import PropTypes from "prop-types";

const Button = (props) => {
	const { onClick, children, className } = props;

	return (
		<button className={className} onClick={onClick}>
			{children}
		</button>
	);
};

Button.propTypes = {
	onClick: PropTypes.func.isRequired,
	children: PropTypes.any.isRequired,
	className: PropTypes.string,
};

export default Button;
