import React from "react";
import strings from "../misc/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PageNotFound() {
	var names = ["Christoffer", "Peter", "Mathias"];
	var randomIndex = Math.floor(Math.random() * names.length);

	return (
		<>
			<h1>{strings.missingPage.title}</h1>
			<h2>
				{strings.missingPage.message.replace("[name]", names[randomIndex])}
			</h2>
		</>
	);
}

export default PageNotFound;
