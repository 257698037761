import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DashboardPage from "./dashboard/DashboardPage";
import MainMenu from "./common/MainMenu";
import Header from "./common/Header";
import PageNotFound from "./PageNotFound";
import ProjectsPage from "./projects/ProjectsPage";
import ProjectPage from "./projects/ProjectPage";
import Login from "./authentication/LoginPage";
import SignoutOidc from "./authentication/signout-oidc";
import SigninOidc from "./authentication/signin-oidc";
import ProtectedRoute from "../utils/protectedRoute";
import TestPage from "./test/TestPage";
import SupplierPage from "./suppliers/SupplierPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import SuppliersPage from "./suppliers/SuppliersPage";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "../redux/store";
import TenantProvider from "../utils/tenantProvider";
import { Provider as ReduxProvider } from "react-redux";

import {
	faClipboard,
	faTrafficLightGo,
	faHome,
	faVial,
	faPlus,
	faBoneBreak,
	faTrashAlt,
	faCog,
	faSitemap,
	faUsers,
	faUserEdit,
	faCertificate,
} from "@fortawesome/pro-light-svg-icons";

import {
	faCheckCircle,
	faExclamationTriangle,
	faExclamationCircle,
	faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";

import { useAuth } from "react-oidc-context";
import UsersPage from "./users/UsersPage";

library.add(
	faClipboard,
	faTrafficLightGo,
	faHome,
	faVial,
	faPlus,
	faBoneBreak,
	faTrashAlt,
	faCog,
	faSitemap,
	faUsers,
	faUserEdit,
	faCheckCircle,
	faExclamationTriangle,
	faExclamationCircle,
	faQuestionCircle,
	faCertificate
);

import strings from "../misc/strings";
strings.setLanguage("se");

function App({ tenantsActions }) {
	const auth = useAuth();

	useEffect(() => {
		// TODO: Ask
		// debugger;
		// auth.signinSilent();
	}, [auth.events, auth.signinSilent]);

	return (
		// <PersistGate loading={null} persistor={persistor}>
		<ReduxProvider store={store}>
			{/* <AuthProvider userManager={userManager} store={store}> */}
			<Router>
				<div id="app">
					<MainMenu />
					<Header />
					<div id="page-container">
						<div id="page">
							<TenantProvider store={store}>
								<Switch>
									<Route path="/login" component={Login} />
									<Route path="/signout-oidc" component={SignoutOidc} />
									<Route path="/signin-oidc" component={SigninOidc} />
									<ProtectedRoute
										path="/suppliers/:supplierId"
										component={SupplierPage}
									/>
									{/* <ProtectedRoute path="/projects/:projectId/suppliers/:supplierId/" component={SupplierPage} /> */}
									<ProtectedRoute
										path="/projects/:projectId/suppliers/:supplierId?"
										component={SuppliersPage}
									/>
									<ProtectedRoute
										path="/projects/:id/"
										component={ProjectPage}
									/>
									<ProtectedRoute path="/projects" component={ProjectsPage} />
									<ProtectedRoute path="/test" component={TestPage} />
									<ProtectedRoute path="/home" component={DashboardPage} />
									<ProtectedRoute path="/users" component={UsersPage} />
									<ProtectedRoute path="/" component={DashboardPage} />
									<Route component={PageNotFound} />;
								</Switch>
							</TenantProvider>

							<ToastContainer
								autoClose={3000}
								hideProgressBar
								position="bottom-right"
							/>
						</div>
					</div>
				</div>
			</Router>
			{/* </AuthProvider> */}
		</ReduxProvider>
		// </PersistGate>
	);
}

// App.propTypes = {
// 	tenantsActions: PropTypes.object,
// };

// function mapDispatchToProps(dispatch) {
// 	return {
// 		tenantsActions: bindActionCreators(tenantsActions, dispatch),
// 	};
// }

// export default connect(null, mapDispatchToProps)(App);
export default App;
